/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components'
import Image from 'next/image'
import { Flex, Logo, useMatchBreakpoints } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useRouter } from 'next/router'
import { Link } from 'react-scroll'
import { useState } from 'react'
import { Drawer } from 'antd'
import UserWalletWithModal from './UserWalletWithModal'
import MenuIcon from './MenuIcon'

const MENU_HEIGHT = 140

const WrapMenu = styled.div`
  overflow: hidden;
  .nav {
    position: fixed;
    z-index: 20;
    background-color: #141414;
    .logo {
      max-height: 50px;
    }
    padding: 17px 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    height: 84px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 84px;
    }
    @media (max-width: 1025px) {
      padding: 17px 20px;
      .left-menu {
        gap: 10px;
      }
    }
  }
  .historyMn {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    z-index: 10;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.6);
    gap: 10px;
  }
`
const InnerBody = styled.div`
  width: 100%;
  // max-width: 1300px;
  margin: 0 auto;
  padding: 0 16px;
  margin-top: ${MENU_HEIGHT}px;
  color: #fff;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 24px;
  }
`
const Footer = styled.div`
  padding: 0px 10px 40px 10px;
  text-align: center;
  color: #686868;
`

const Menu = ({ children }) => {
  const router = useRouter()
  const { isDesktop } = useMatchBreakpoints()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const closeMobileMenu = () => {
    if (!isDesktop) {
      setIsMenuOpen(false)
    }
  }
  return (
    <WrapMenu>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="nav">
        <div style={{ minWidth: 148, minHeight: 50, cursor: 'pointer' }}>
          <Image src="/images/logo/logo.png" alt="logo" width={148} height={50} />
        </div>
        <Drawer onClose={toggleMenu} style={{ background: '#0C0C0B' }} open={isMenuOpen} title={null}>
          <Flex flexDirection="column" className="left-menu" style={{ gap: 35 }}>
            <Link
              onClick={toggleMenu}
              offset={-70}
              smooth
              to="home"
              style={{ color: '#FFF', fontSize: 20, fontWeight: '600' }}
            >
              Home
            </Link>
            <Link
              onClick={toggleMenu}
              offset={-70}
              smooth
              to="governence"
              style={{ color: '#FFF', fontSize: 20, fontWeight: '600' }}
            >
              Governence
            </Link>
            <a onClick={toggleMenu} href="#" style={{ color: '#FFF', fontSize: 20, fontWeight: '600' }}>
              Documents
            </a>
            <Link
              onClick={toggleMenu}
              offset={-70}
              smooth
              to="security"
              style={{ color: '#FFF', fontSize: 20, fontWeight: '600' }}
            >
              Security
            </Link>
            <a onClick={toggleMenu} href="#" style={{ color: '#FFF', fontSize: 20, fontWeight: '600' }}>
              Community
            </a>
            <Link
              onClick={toggleMenu}
              offset={-70}
              smooth
              to="faq"
              style={{ color: '#FFF', fontSize: 20, fontWeight: '600' }}
            >
              FAQ
            </Link>
          </Flex>
          <Flex style={{ marginTop: 30 }}>
            <UserWalletWithModal />
          </Flex>
        </Drawer>
        {!isDesktop ? (
          <div onClick={toggleMenu}>
            <MenuIcon />
          </div>
        ) : (
          <>
            {' '}
            <Flex className="left-menu" alignItems="center" style={{ gap: 35 }}>
              <Link offset={-70} smooth to="home" style={{ color: '#FFF', cursor: 'pointer' }}>
                Home
              </Link>
              <Link offset={-70} smooth to="governence" style={{ color: '#FFF', cursor: 'pointer' }}>
                Governence
              </Link>
              <a
                href="https://beurl.app/zSIHGTFI"
                target="_blank"
                style={{ color: '#FFF', cursor: 'pointer' }}
                rel="noreferrer"
              >
                Documents
              </a>
              <Link offset={-70} smooth to="security" style={{ color: '#FFF', cursor: 'pointer' }}>
                Security
              </Link>
              <a href="#" style={{ color: '#FFF', cursor: 'pointer' }}>
                Community
              </a>
              <Link offset={-70} smooth to="faq" style={{ color: '#FFF', cursor: 'pointer' }}>
                FAQ
              </Link>
            </Flex>
            <Flex>
              <UserWalletWithModal />
            </Flex>
          </>
        )}
      </div>
      <InnerBody>{children}</InnerBody>
      <Footer>Copyright © 2024 Udefi. All rights reserved</Footer>
    </WrapMenu>
  )
}

export default Menu
